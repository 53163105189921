import React from 'react'

import {Alert, Button, IconButton, Modal, H4, P, ListItem, ProgressBar, RadioButton, Colors, DatePicker, Input, Select} from '../UI/index.js'

export default class Popup extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            isOpen: false,
            loading: false,
            title: '',
            text: '',
            inputs: [],
            input: null,
            inputValue: '',
            options: [],
            progressNow: 0,
            progressMax: null,
            onConfirm: null,
            onClose: null,
            message: '',
            info: '',
            warning: '',
            warnings: [],
            error: '',
            errors: []
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))

        this.onKeyDown = this.onKeyDown.bind(this)
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    onKeyDown(event) {
        const {noEnter} = this.props
        const {isOpen, options} = this.state

        if (isOpen && !noEnter && event.keyCode === 13 && !options?.length) {
            this.onClickConfirm()
        }
    }

    open(title, text, onConfirm, onClose) {
        this.setState({isOpen: true, title, text, onConfirm, onClose})

        document.addEventListener('keydown', this.onKeyDown)

        if (!onConfirm) {
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        }
    }

    setInput(input) {
        this.setState({input, inputValue: input.value})
    }

    setInputs(inputs) {
        this.setState({inputs})
    }

    setInputValue(index, value) {
        const inputs = [...this.state.inputs]

        if (Array.isArray(value)) {
            inputs[index].values = value
        } else {
            inputs[index].value = value
        }

        this.setState({inputs})
    }

    openOptions(title, text, options, onConfirm) {
        this.setState({isOpen: true, title, text, options, onConfirm})

        if (!onConfirm) {
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        }
    }

    close() {
        this.setState({isOpen: false}, () => {
            typeof this.state.onClose === 'function' && this.state.onClose()
            this.setState(this.initialState)
        })

        document.removeEventListener('keydown', this.onKeyDown)
    }

    onClickOption(option) {
        this.close()

        if (typeof this.state.onConfirm === 'function') {
            this.state.onConfirm(option)
        } else {
            this.resolve(option)
        }
    }

    onClickConfirm() {
        this.setState({loading: true, message: '', error: ''})

        if (typeof this.state.onConfirm === 'function') {
            this.state.onConfirm()
        } else {
            if (this.state.input) {
                this.resolve(this.state.inputValue)
            } else if (this.state.inputs) {
                const values = {}

                this.state.inputs.map((input) => {
                    values[input.name] = input.values || input.value
                })

                this.resolve(values)
            } else {
                this.resolve(true)
            }
        }
    }

    setMessage(message) {
        this.setState({isOpen: true, message, loading: false})
    }

    setInfo(info) {
        this.setState({isOpen: true, info})
    }

    setWarning(warning) {
        this.setState({isOpen: true, warning})
    }

    setWarnings(warnings) {
        this.setState({warnings})
    }

    setError(error) {
        this.setState({isOpen: true, error, loading: false})
    }

    setErrors(errors) {
        this.setState({isOpen: true, errors, loading: false})
    }

    setProgress(progressNow, progressMax) {
        this.setState({isOpen: true, progressNow, progressMax})
    }

    render() {
        const {isOpen, title, inputs, input, inputValue, text, options, progressNow, progressMax, loading, message, info, warning, warnings, error, errors} = this.state
        const {noEsc, closeButton, showOptionRadioButton, confirmBtnText, cancelBtnText, noCancelBtn} = this.props

        return (
            <Modal show={isOpen} noEsc={noEsc} style={{width: errors.length > 0 ? 800 : 400, maxWidth: '90%'}} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: 24}}>

                    <H4>{title || 'Foutmelding'}</H4>

                    <div style={{flex: 1}} />

                    {closeButton &&
                        <IconButton onClick={this.close.bind(this)}>
                            <i style={{color: Colors.buttonSolid}} className='mdi mdi-close' />
                        </IconButton>
                    }
                </div>

                {text &&
                    <P style={{marginBottom: 30, whiteSpace: 'pre-wrap'}}>{text}</P>
                }

                {input?.type === 'date' &&
                    <DatePicker
                        label={input.label}
                        value={inputValue}
                        onChange={(event) => this.setState({inputValue: event.target.value})}
                        disabled={input.disabled}
                    />
                }

                {input?.type === 'text' &&
                    <Input
                        label={input.label}
                        value={inputValue}
                        onChange={(event) => this.setState({inputValue: event.target.value})}
                        max={input.max}
                        disabled={input.disabled}
                    />
                }

                {input?.type === 'number' &&
                    <Input
                        label={input.label}
                        value={inputValue}
                        onChange={(event) => this.setState({inputValue: event.target.value})}
                        type='number'
                        min={input.min}
                        max={input.max}
                        disabled={input.disabled}
                    />
                }

                {inputs.length > 0 && inputs.map((input, index) => {
                    if (input.type === 'date') {
                        return (
                            <DatePicker
                                key={`input${index}`}
                                label={input.label}
                                value={input.value}
                                onChange={(event) => this.setInputValue(index, event.target.value)}
                                disabled={input.disabled}
                            />
                        )
                    }

                    if (input.type === 'text') {
                        return (
                            <Input
                                key={`input${index}`}
                                label={input.label}
                                value={input.value}
                                onChange={(event) => this.setInputValue(index, event.target.value)}
                                max={input.max}
                                disabled={input.disabled}
                            />

                        )
                    }

                    if (input.type === 'number') {
                        return (
                            <Input
                                key={`input${index}`}
                                label={input.label}
                                value={input.value}
                                onChange={(event) => this.setInputValue(index, event.target.value)}
                                type='number'
                                min={input.min}
                                max={input.max}
                                disabled={input.disabled}
                            />
                        )
                    }

                    if (input.type === 'select') {
                        return (
                            <Select
                                key={`input${index}`}
                                label={input.label}
                                value={input.value}
                                values={input.values}
                                options={input.options}
                                onChange={(event) => this.setInputValue(index, event.target.values || event.target.value)}
                                disabled={input.disabled}
                            />
                        )
                    }
                })}

                {!!options.length &&
                    <div style={{maxHeight: 600, overflowY: 'auto'}}>
                        {options.map((option) => {
                            if (typeof option.title === 'string' && typeof option.value === 'string') {
                                return (
                                    <ListItem
                                        key={`${option.value}${option.subvalue || ''}`}
                                        disabled={option.disabled}
                                        onClick={option.disabled ? () => {} : () => this.onClickOption(option)}
                                    >
                                        {showOptionRadioButton &&
                                            <RadioButton
                                                style={{marginBottom: 0}}
                                                disabled={option.disabled}
                                                checked={option.selected}
                                            />
                                        }

                                        <P ellipsis style={{flex: 1}} disabled={option.disabled}>{option.title}</P>

                                        {option.append &&
                                            <div style={{marginLeft: 6}}>{option.append}</div>
                                        }
                                    </ListItem>
                                )
                            } else {
                                return option
                            }
                        })}
                    </div>
                }

                {progressMax &&
                    <ProgressBar now={progressNow} max={progressMax} />
                }

                {message &&
                    <Alert variant='success'>{message}</Alert>
                }

                {info &&
                    <Alert variant='info'>{info}</Alert>
                }

                {warning &&
                    <Alert variant='warning'>{warning}</Alert>
                }

                {warnings.length > 0 && warnings.map((warning, index) => (
                    <Alert key={`warning${index}`} variant='warning'>{warning}</Alert>
                ))}

                {error &&
                    <Alert variant='danger'>{error}</Alert>
                }

                {errors.length > 0 && errors.map((error, index) => {
                    return <Alert key={`error${index}`} variant='danger'>{error}</Alert>
                })}

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    {!noCancelBtn && !message && !error && !options.length &&
                        <Button
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            {cancelBtnText || 'Annuleren'}
                        </Button>
                    }

                    {!message && !error && !options.length &&
                        <Button
                            variant='text'
                            loading={loading}
                            onClick={this.onClickConfirm.bind(this)}
                        >
                            {confirmBtnText || 'Bevestigen'}
                        </Button>
                    }

                    {(message || error) &&
                        <Button
                            style={{marginBottom: 0}}
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Sluiten
                        </Button>
                    }
                </div>
            </Modal>
        )
    }
}
